import { IonContent, IonToast } from '@ionic/react';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import { hideToast, selectToast } from '../store/toastSlice';



const ToastComponent: React.FC = () => {

    const toast = useAppSelector(selectToast);
    const dispatch = useAppDispatch();

    return (        
        <IonContent>
            <IonToast
                color={toast.type}
                isOpen={toast.show}
                message={toast.message}
                onDidDismiss={() => dispatch(hideToast())}
                duration={1500}
            />
        </IonContent>
    );
}

export default ToastComponent;