export interface User {
    iat: number;
    exp: number;
    sub: string;
    roles: UserRole[];
    name: string;
    customer?: any;
}

export enum UserRole {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_EMPLOYEE = "ROLE_EMPLOYEE",
    ROLE_USER = "ROLE_USER",
}
