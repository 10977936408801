import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface ToastState {
  type: string,
  show: boolean,
  message: string
}

const initialState: ToastState = {
  type: '',
  show: false,
  message: ''
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<{type: string, message: string}>) => {
      state.type = action.payload.type
      state.show = true;
      state.message = action.payload.message;
    },    
    hideToast: state => {
      state.show = false;
    }
  }
});

export const { showToast, hideToast } = toastSlice.actions
export const selectToast = (state: RootState) => state.toast
export default toastSlice.reducer